<template>
    <modal ref="modalVerImagenes" titulo="" tamano="modal-lg">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-11 mt-3">
                    <el-image ref="refOpenImages" style="width: 100px; height: 100px" :src="soloImg[0]" :preview-src-list="soloImg" class="d-none" />
                    <vueper-slides ref="vueperslides1" class="vueperslides--1" :touchable="false" fade="fade" :autoplay="false" :bullets="false" fixed-height="325px" :arrows="true" @slide="$refs.vueperslides2 && $refs.vueperslides2.goToSlide($event.currentSlide.index, { emit: false })">
                        <template #arrow-right>
                            <div class="bg-white wh-32px rounded-circle d-middle-center text-black">
                                <i class="icon-right-open" />
                            </div>
                        </template>
                        <template #arrow-left>
                            <div class="bg-white wh-32px rounded-circle d-middle-center text-black">
                                <i class="icon-left-open" />
                            </div>
                        </template>
                        <vueper-slide v-for="(slide, i) in imagenes" :key="i" :image="slide" class="cr-pointer" @click.native="openModalImg" />
                    </vueper-slides>

                    <vueper-slides ref="vueperslides2" class="no-shadow vueperslides--2 mt-3 mb-0" :visible-slides="5" fixed-height="87px" :bullets="false" :touchable="false" :gap="2.5" :arrows="true" @slide="$refs.vueperslides1 && $refs.vueperslides1.goToSlide($event.currentSlide.index, { emit: false })">
                        <template #arrow-right>
                            <i class="icon-right-open" />
                        </template>
                        <template #arrow-left>
                            <i class="icon-left-open" />
                        </template>
                        <vueper-slide v-for="(slide, i) in imagenes" :key="i" :image="slide" @click.native="$refs.vueperslides2 && $refs.vueperslides2.goToSlide(i)" />
                    </vueper-slides>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
export default {
    data(){
        return{
            imagenes: [],
            index: null,
            soloImg: [],
        }
    },
    methods:{
        toggle(index,imagenes){
            this.index = index
            this.imagenes = imagenes.map(el=>el.ruta);
            this.soloImg = this.imagenes;
            this.$refs.modalVerImagenes.toggle()
        },
        guardarValores(){
            this.$refs.modalVerImagenes.toggle()
        },
        openModalImg(){
            this.$refs.refOpenImages.$el.childNodes[0].click();
        },
    }
}
</script>
<style lang="scss" scoped>

</style>